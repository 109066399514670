<template>
  <div class="hold-transition login-page" style="background-image:url(/assets/images/login-bg.jpg)">
    <div class="login-box">
      <a href="/"><img src="/assets/images/logo.svg" alt="Dotlines"></a>
      <form @submit.prevent="forget_pass()" method="POST" class="response-alert" novalidate>
        <div class="form-group">
          <label class="control-label"><i class="fas fa-user"></i> Email or Mobile</label>
          <input type="text" id="mobile_or_email" name="mobile_or_email" v-model.trim="user.mobile_or_email"
                 class="form-control" placeholder="email or mobile"
                 v-validate="'required'"
                 :class="{'has-error' : errors.has('mobile_or_email')}">
          <div class="help text-danger" v-show="errors.has('mobile_or_email')">
            {{ errors.first('mobile_or_email') }}
          </div>
        </div>
        <div class="col-6 text-left mb-2">
          <router-link :to="{name: 'Login'}">
            <i class="fas fa-angle-left"></i> Back to login
          </router-link>
        </div>
        <button type="submit" class="btn btn-common btn-block">Forgot Password <i class="fas fa-arrow-right"></i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import GuestLayout from '@/components/layouts/GuestLayoutComponent.vue';
import axios from 'axios';

export default {
  metaInfo() {
    return {
      title: 'Forgot Password | Dotlines',
      meta: [
        {
          name: 'description',
          content: 'The app built by Dotlines that is simply a framework'
        },
        {
          property: 'og:title',
          content: 'Forgot Password | Dotlines'
        }
      ],
    }
  },
  data: () => ({
    user: {}
  }),
  methods: {
    forget_pass: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let api_url = this.API_BASE_URL + '/auth/forgot-password';
          let token = '';
          let _this = this;
          axios({
            method: 'post',
            url: api_url,
            data: _this.user
          }).then(function (response) {
            if (response.status === 200 && response.data.otp_success === true) {
              _this.$router.push({name: 'ResetPass', params: {userInfo: response.data}});
            } else {
              $(".response-alert").prepend($(
                '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
                '    Invalid email or mobile no!\n' +
                '    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '       <span aria-hidden="true">&times;</span>\n' +
                '    </button>\n' +
                '</div>'));
            }
          }).catch(function (error) {
            if (error.response) {
              $(".response-alert").prepend($(
                '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
                error.response.data.message +
                '    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
                '       <span aria-hidden="true">&times;</span>\n' +
                '    </button>\n' +
                '</div>'));
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }

          }).finally(function () {
            _this.is_loading = false;
            _this.is_disabled = false;
            setTimeout(() => {
              $('.alert').fadeOut('slow');
            }, 5000);
          });

          console.warn(this.user)
        }
      });
    },

  },
  mounted: function () {
    // localStorage.setItem("authenticate", false);
    // localStorage.removeItem("token");
  },
  created() {
    this.$emit('update:layout', GuestLayout)
  }
}
</script>
